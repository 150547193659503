/* ########################  MODIFICHE STILE BOOTSTRAP ###############################  */

@import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
.modal-modifica-align {
    min-width: 150px;
}

.alert-danger {
    color: white;
    background-color: red;
    border-color: #ebccd1;
    animation: blinkingText 2s infinite;
}

@keyframes blinkingText {
    0% {
        color: white;
    }
    10% {
        color: white;
    }
    20% {
        color: white;
    }
    30% {
        color: white;
    }
    40% {
        color: white;
    }
    49% {
        color: transparent;
    }
    50% {
        color: transparent;
    }
    99% {
        color: transparent;
    }
    100% {
        color: white;
    }
}

.mobile-insert-data {
    width: 50% !important;
}

.modal-body {
    color: black;
}

.modal-title {
    color: black;
}

.modal-footer {
    color: black;
    border-top: 0 !important;
}

.panel-default>.panel-heading {
    background-color: #09abff;
    color: black;
    font-weight: bold
}

/* ######################## FINE MODIFICHE STILE BOOTSTRAP ###############################  */

.suggerimento {}

.tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #ff0000;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 10%;
    bottom: 100%;
}

.suggerimento:hover .tooltiptext {
    visibility: visible;
}

h3 {
    font-size: 1.65vw;
}

body {
    background: #040f23 !important;
    color: white !important;
    overflow-x: hidden;
}

.btn-header {
    margin-left: 1px;
    margin-top: 1px;
    min-width: 138px;
}

.col {
    text-align: center;
}

.header {
    position: fixed;
    top: -40px;
    left: 0;
    width: 100%;
    height: 188px;
    z-index: 12;
    background: #0e0b2b;
}

#header a.current {
    background: lightskyblue !important;
    color: white !important;
}

.btn-default.active {
    background: #337ab7 !important;
    color: white !important;
}

.erroreForm {
    margin-top: 1px;
    margin-bottom: 2px;
}

.center-div {
    position: absolute;
    margin: auto;
    top: 100%;
    right: 0;
    bottom: 0;
    left: 0;
}

.label-searchbar {
    font-size: 1.0vw;
}

/* ######################## TIME PICKER BOOTSTRAP ###############################  */

ngb-datepicker-navigation-select>.custom-select {
    font-size: 1.2rem !important;
}

.datepicker-custom {
    color: black;
    width: 50%;
}

ngb-datepicker {
    color: #fff;
}

.datepicker-custom .bg-primary {
    color: #ff0e0e !important;
}

.ngb-tp .form-control {
    font-weight: bold !important;
    min-width: 50px;
    margin-left: 5px;
    margin-right: 5px;
}

.ngb-tp .ngb-tp-spacer {
    margin-left: 5px;
    margin-right: 5px;
}

/* .ngb-dp-arrow {
    visibility: hidden;
} */

/* ######################## FINE TIME PICKER BOOTSTRAP ###############################  */

/* ######################## GESTIONE RISTORANTE ###############################  */

.secondo-elemento-lista {
    background-color: #f1eded;
}

/* ######################## FINE GESTIONE RISTORANTE ###############################  */

/* ######################## GESTIONE MAGAZZINO ###############################  */

.text-item-magazzino {
    font-size: 18px;
    /* font-size: 1.2vw;*/
    margin-top: 0;
    margin-bottom: 0;
    font-family: inherit;
    font-weight: 500;
    line-height: 1.1;
}

/* ######################## FINE GESTIONE MAGAZZINO ###############################  */

/* ######################## PAGINA GESTIONE OMBRELLONI   ###############################  */

.st0:hover {
    stroke: white;
    stroke-width: 1;
}

.st2:hover {
    stroke: white;
    stroke-width: 1;
}

.st1:hover {
    stroke: white;
    stroke-width: 1;
}

.st0 {
    fill: #20ab20;
/*     stroke: #000000; */
    stroke-miterlimit: 10;
}

.st2 {
    fill: red;
/*     stroke: #000000; */
    stroke-miterlimit: 10;
}

.st1 {
    fill: #1f91f3;
/*     stroke: #000000; */
    stroke-miterlimit: 10;
    /* pointer-events: none; */
}

.foto-profilo {
    max-width: 110px !important;
}

.testo-ombr {
    text-decoration: none !important;
    font-size: 0.6rem;
    fill: white;
}

.testo-ombr:hover {
    text-decoration: none !important;
}

svg a:hover {
    text-decoration: none !important;
}

/* ######################## FINE PAGINA GESTIONE OMBRELLONI   ###############################  */

/* ######################## PAGINA GESTIONE ORDINI BAR   ###############################  */

.allinea-sx {
    float: left !important;
}

.allinea-dx {
    float: right !important;
}

.ordinePagatoOn {
    background-color: green !important;
    color: #fff !important;
}

.ordinePagatoOff {
    background-color: #d4d4d4 !important;
    border-color: #d4d4d4 !important;
    color: black !important;
}

.ordineNonPagatoOn {
    background-color: #ac2925 !important;
    border-color: #761c19 !important;
    color: #fff !important;
}

.ordineNonPagatoOff {
    background-color: #d4d4d4 !important;
    color: black !important;
    border-color: #d4d4d4 !important;
}

.ordine-disabilitato-on {
    pointer-events: none;
}

.ordine-disabilitato-off {
    pointer-events: all;
}

/* ######################## FINE PAGINA GESTIONE ORDINI BAR   ###############################  */

@media screen and (min-width: 320px) and (max-width: 767px) {
    .foto-profilo {
        max-width: 55px !important;
    }
    .btn-header {
        margin-top: 2px;
        min-width: 95% !important;
    }
    .btn.btn-info.btn-md {
        width: 100%;
    }
    #login-column {
        width: 100%;
    }
    .mobile-insert-data {
        width: 100% !important;
        margin-bottom: 15px;
    }
    .alert-danger {
        font-size: 1.8vh !important;
    }
}

@media screen and (min-width: 1366px) and (max-width: 1919px) {
    .foto-profilo {
        max-width: 65px !important;
    }
}

.bg-primary {
    color: #ff0e0e !important;
}

.ngb-dp-navigation-select {
    color: black !important;
}

ngb-datepicker-month-view, ngb-datepicker {
    background-color: #040f23;
}

.ngx-pagination {
    text-align: center !important;
}

#gestione-ordini-bar .ngx-pagination .disabled {
    color: grey !important;
}

#gestione-ordini-bar .ngx-pagination a, .ngx-pagination button {
    color: white;
}

.ngb-dp-day.hidden {
    display: block !important;
    visibility: hidden !important;
}

#storico-ordini .center-div,#gest-clienti .center-div  {
    width: 100%;

}
